<template>
	<div class="users-list list-view" :class="{'assigned-users': isEditClient}">
		<div v-if="isEditClient" class="list-header">
			<div class="col">
				<p>Users assigned</p>
			</div>
			<!-- end col -->
		</div>
		<!-- end list-header -->
		<div class="list-header three-col">
			<div v-if="!isEditClient" class="col span-2">
				<p>Users</p>
			</div>
			<!-- end col -->
			<div v-if="!isEditClient" class="col">
				<p>Clients assigned</p>
			</div>
			<!-- end col -->
			<div class="col last">
				<form id="search-items">
					<input type="text" placeholder="Search" v-model="usernameSearchQuery" />
					<button class="submit" type="submit"><i class="fas fa-search"></i></button>
				</form>
			</div>
			<!-- end col -->
		</div>
		<!-- end list-header -->
		<div class="grouping" v-for="(value, letter) in sortedUsers" :key="letter" :data-letter="letter">
			<div class="col">
				<div class="heading">
					<div class="letter" :id="letter">
						<p>{{ letter }}</p>
					</div>
				</div>
				<!-- end heading -->
			</div>
			<!-- end col -->
			<div class="col span-3">
				<div class="users items">
					<article class="user item grid-container" v-for="user in value" :key="user.username">
						<div class="col user-info">
							<router-link :to="{path: `/admin/user-management/${user.username}/edit/`}">
								<div class="profile-image">
									<img :src="user.avatarUrl" :alt="user.username" :title="user.username"  v-if="user.avatarUrl" />
                                    <div class="user-letter" v-else>{{user.username.charAt(0)}}</div>
								</div>
								<span class="user-name">{{ user.username }}</span>
							</router-link>
						</div>
						<!-- end col -->
						<div v-if="!isEditClient" class="col clients">
							<template v-if="user.Clients.length > 0">
								<router-link :to="{path: `/client/${client.slug}/dashboard/`}" class="client-image" v-for="client in user.Clients" :key="client.id">
                                        <img :src="client.avatarUrl"  v-if="client.avatarUrl" />
                                        <div class="client-letter" v-else>{{client.slug.charAt(0)}}</div>
								</router-link>
							</template>
						</div>
						<!-- end col -->
						<div v-if="isEditClient" class="col last remove">
							<button class="remove" title="Unassign user" v-on:click="unassignUser(user)">
								<i class="fas fa-minus-circle"></i>
							</button>
						</div>
						<!-- end col -->
						<div v-if="!isEditClient" class="col last edit">
							<span class="edit" title="Edit user">
								<router-link :to="{path: `/admin/user-management/${user.username}/edit/`}">
									<i class="fad fa-cog"></i>
								</router-link>
							</span>
						</div>
						<!-- end col -->
					</article>
				</div>
				<!-- end users -->
			</div>
			<!-- end col -->
		</div>
	</div>
	<!-- end users-list -->
</template>
<script>

import {mapGetters} from 'vuex';
export default {
	data() {
		return {
			usersArray: [],
			sortedUsers: {}, //use to order users by letter
			usernameSearchQuery: "",
			clientsListKey: 1,
		}
	},
    watch:{
        usernameSearchQuery(){
            this.searchUsersByUsername()
        }
    },
	mounted() {
		this.getUsers()
	},
	computed: {
		isEditClient() {
			return this.$route.name === "Edit Client" || this.$route.name === "Edit Clients"
		},
        ...mapGetters("client", ['getClientSlug'])
	},
	methods: {
        searchUsersByUsername(){
            this.getUsers(this.usernameSearchQuery);
        },
		forceUpdate() {
			this.$forceUpdate()
		},
        async unassignUser(user){
            let confirmed = confirm(`Are you sure you want to unassign ${user.fullName} from this client?`);
            let userIds = [user.id];
            if(confirmed){
                let clientSlug = this.getClientSlug;
                await this.$store.dispatch('removeClientUserRelations', {clientSlug, userIds})
                await this.$store.dispatch("client/fetchClientData", clientSlug)
                this.$emit('userRemoved');
            }
        },
		async getUsers(optionalUsernameSearchQuery = undefined) {
            this.usersArray = [];
			try {
				if (!this.isEditClient) {
					this.usersArray = await this.$store.dispatch("user/getAllUsers")
				} else {
					this.usersArray = [...this.$store.getters["client/getClientUsers"]]
				}
				this.sortUsers(optionalUsernameSearchQuery)
			} catch (error) {
				console.log("oops an error", error)
			}
		},
		sortUsers(optionalUsernameSearchQuery = undefined) {

            // Clear the object
            this.sortedUsers = {};

			//get the first letter of client name
			function findFirstLetter(string) {
				return string.charAt(0)
			}

            // If we are provided an optional search query
            if(optionalUsernameSearchQuery){
                // Filter the usersArray based on a partial string match
                this.usersArray = this.usersArray.filter((user)=>user.username.toLowerCase().includes(optionalUsernameSearchQuery.toLowerCase()));
            }
		
			// Sort by alphabetical order
			let alphabeticalUsersArray = this.usersArray.sort((a, b) => {
				let fa = a.username.toLowerCase(),
					fb = b.username.toLowerCase()
				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})

			//sort array items under each letter
			alphabeticalUsersArray.forEach((user) => {
				let userFirstLetter = findFirstLetter(user.username).toUpperCase()
				if (this.sortedUsers[userFirstLetter]) {
					this.sortedUsers[userFirstLetter] = [...this.sortedUsers[userFirstLetter], user]
				} else {
					this.sortedUsers[userFirstLetter] = [user]
				}
			})
		},
		warn: function (message, event) {
			if (event) {
				event.preventDefault()
			}
			if (confirm(message)) {
				alert("confirmed")
			} else {
				alert("cancelled")
			}
		},
	},
}
</script>

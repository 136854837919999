<template>
	<div class="add-user-form" v-if="isEditUser || (!isEditUser && can(getAuthData, 'create_global_user'))">
		<p class="show-form toggle-button dark" v-bind:class="{active: isActive}" data-container="toggle-1" v-on:click="toggleForm()" v-if="this.$route.name !== 'Edit User' && this.$route.name !== 'Edit Users'">
			<span class="add"><i class="fas fa-plus-circle"></i></span> Add a user
		</p>
		<div class="toggle-container" v-bind:class="{active: isActive}" v-if="isActive" id="toggle-1">
			<form id="add-user-form" class="dark" v-on:submit.prevent="generatedPassword" novalidate>
				<div class="form-row">
					<template v-if="can(getAuthData, 'read_own_user_avatar', {username: getUserUsername}) || can(getAuthData, 'read_other_user_avatar', {}, {username: getUserUsername}) || !isEditUser">
						<div class="upload-photo">
							<div class="photo" :style="avatarBgImage()">
								<img v-bind:src="avatarBgImage()" v-if="avatarBgImage()" />
							</div>
							<label for="upload-photo">Upload profile picture</label>
							<input
								type="file"
								id="upload-photo"
								name="upload-photo"
								v-on:change="selectFile($event)"
								:disabled="
									isEditUser && // Disabled if on edit user page
									!(can(getAuthData, 'update_own_user_avatar', {username: getUserUsername}) || can(getAuthData, 'update_other_user_avatar', {}, {username: getUserUsername}))
								"
							/>
						</div>
						<!-- end upload-photo -->
					</template>
				</div>
				<!-- end form-row -->

				<template v-if="can(getAuthData, 'read_own_user_name', {username: getUserUsername}) || can(getAuthData, 'read_other_user_name', {}, {username: getUserUsername}) || !isEditUser">
					<div class="form-row">
						<input class="half" type="text" id="firstName" :disabled="isEditUser && !(can(getAuthData, 'update_own_user_name', {username: getUserUsername}) || can(getAuthData, 'update_other_user_name', {}, {username: getUserUsername}))" placeholder="First Name" required v-model="firstName" />
					</div>
					<div class="form-row">
						<input
							class="half"
							type="text"
							id="lastName"
							:disabled="
								isEditUser && // Disabled if on edit user page
								!(can(getAuthData, 'update_own_user_name', {username: getUserUsername}) || can(getAuthData, 'update_other_user_name', {}, {username: getUserUsername}))
							"
							placeholder="Last Name"
							required
							v-model="lastName"
						/>
					</div>
				</template>
				<template v-if="can(getAuthData, 'read_own_user_email', {username: getUserUsername}) || can(getAuthData, 'read_other_user_email', {}, {username: getUserUsername}) || !isEditUser">
					<div class="form-row">
						<input
							type="email"
							id="email"
							:disabled="
								isEditUser && // Disabled if on edit user page
								!(can(getAuthData, 'update_own_user_email', {username: getUserUsername}) || can(getAuthData, 'update_other_user_email', {}, {username: getUserUsername}))
							"
							placeholder="Email"
							required
							v-model="email"
						/>
					</div>
				</template>

				<template v-if="can(getAuthData, 'read_own_user_username', {username: getUserUsername}) || can(getAuthData, 'read_other_user_username', {}, {username: getUserUsername}) || !isEditUser">
					<div class="form-row">
						<input
							type="text"
							id="username"
							:disabled="
								isEditUser && // Disabled if on edit user page
								!(can(getAuthData, 'update_own_user_username', {username: getUserUsername}) || can(getAuthData, 'update_other_user_username', {}, {username: getUserUsername}))
							"
							placeholder="Username"
							required
							v-model="username"
							:class="{error: v$.$error}"
						/>
						<IconErrors v-if="v$.$error" :errors="v$.$errors" />
					</div>
				</template>
				<template v-if="can(getAuthData, 'update_own_user_password', {username: getUserUsername}) || can(getAuthData, 'update_other_user_password', {}, {username: getUserUsername}) || !isEditUser">
					<div class="form-row password">
						<div class="input-wrap">
							<input type="text" id="password" :class="{error: v$.$error}" placeholder="Password" :required="getAuthData" v-model="password" />
							<span class="copy" v-if="password" v-on:click="copy(password)" title="Copy to clipboard">
								<i class="fas fa-clipboard"></i>
							</span>
							<IconErrors v-if="v$.$error" :errors="v$.$errors" />
						</div>
						<!-- end input-wrap -->
						<p class="generate-password" v-on:click="generatePassword">{{ isEditUser ? "Reset" : "Generate" }}</p>
					</div>
				</template>
				<!-- end form-row -->
				<!-- <input type="email" id="email" placeholder="Email" required v-model="email" /> -->
				<template v-if="can(getAuthData, 'read_own_user_role', {username: getUserUsername}) || can(getAuthData, 'read_other_user_role', {}, {username: getUserUsername})">
					<div class="form-row">
						<div class="styled-select select" id="user-roles">
							<VueNextSelect
								v-bind:placeholder="usersRole || 'Role'"
								v-bind:options="rolesArray"
								label-by="name"
								@selected="($event, payload) => updateUserData($event, 'globalRoleId', payload)"
								close-on-select
								:disabled="
									isEditUser && // Disabled if on edit user page
									!(can(getAuthData, 'update_own_user_role', {username: getUserUsername}) || can(getAuthData, 'update_other_user_role', {}, {username: getUserUsername}))
								"
							></VueNextSelect>
						</div>
					</div>
				</template>
				<!-- end styled-select -->
				<!-- end select-wrap -->
				<button type="submit" @click.prevent="submitForm()">Save</button>
				<p class="error-message" v-if="formMessage">{{ formMessage }}</p>
				<Errors :errors="responseErrors" />
			</form>
		</div>
		<!-- end add-user-form -->
	</div>
	<!-- end add-user -->
</template>
<script>
import VueNextSelect from "vue-next-select"
import "vue-next-select/dist/index.min.css"
import Errors from "./validation/Errors.vue"
import IconErrors from "./validation/iconErrors.vue"
import useValidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import {mapGetters} from "vuex"
export default {
	data() {
		return {
			v$: useValidate(),
			isActive: this.$route.name === "Edit User" || this.$route.name === "Edit Users" ? true : false,
			selectedFile: null,
			previewUrl: "",
			errorMessage: "",
			formMessage: "",
			rolesArray: [
				{roleTitle: "Operator", roleId: "operator"},
				{roleTitle: "Account Manager", roleId: "accountManager"},
				{roleTitle: "User", roleId: "user"},
			],
		}
	},
	mounted(){
		console.log('test:', this.$route.name);
	},
	setup() {
		//generate new password
		//copy to clipboard
		
		function copy(str) {
			navigator.clipboard.writeText(str)
			alert("Password copied")
		}
		return {copy}
	},
	created() {
		this.getAllRolesWithLowerRank();
	},
	validations() {
		return {
			username: {
				required: required,
			},
			password: {
				required: this.$route.name !== "Edit User" && this.$route.name !== "Edit Users" ? required : false
			},
		}
		},
	components: {
		VueNextSelect,
		Errors,
		IconErrors,
	},
	methods: {
		async getAllRolesWithLowerRank() {
			this.rolesArray = await this.$store.dispatch("roles/getAllRolesWithLowerRank")
		},
		avatarBgImage() {
			let bgImage = this.previewUrl ? this.previewUrl : this.getUserAvatarUrl
			return bgImage
		},
		generatePassword() {
			const passwordLength = 16
			const chars = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!?/*@£$%&#(){}[]=+_-<>^;"
			const target = "password"
			let value = ""

			for (let i = 0; i < passwordLength; i++) {
				value += chars.charAt(Math.floor(Math.random() * chars.length))
			}

			this.$store.commit("user/updateUserData", {target, value})
		},
		toggleForm() {
			this.isActive = !this.isActive
		},
		selectFile($event) {
			let file = $event.target.files[0]
			let allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"]

			// Check if file is of allowed types
			if (!allowedFileTypes.includes(file.type)) {
				alert("File must be a jpg, jpeg or png")
			} else if (file.size > 1048576) {
				// Check if file is below 1mb
				alert("File is larger than 1mb")
			} else {
				this.selectedFile = file
				this.previewUrl = URL.createObjectURL(this.selectedFile)
				// console.log(this.previewUrl)
			}
		},
		async submitForm() {
			this.v$.$validate()
			console.log('pre form submit', this.getAuthData);
			if (this.v$.$errors.length > 0) {
				return (this.responseErrors = [{$message: "Form has errors, please check and try again."}])
			} else {
				this.responseErrors = [{$message: ""}];
				if (this.$route.name === "Edit User" || this.$route.name === "Edit Users") {
					const response = await this.$store.dispatch("user/updateUser", this.selectedFile)
					this.formMessage = response.message;
				} else {
					this.formMessage = await this.$store.dispatch("user/createUser", this.selectedFile)
					this.$emit("newUserAdded")
				}
			}
		},
		updateUserData($event, target, payload) {
			let value
			if (target === "globalRoleId") {
				payload = $event.id
				this.userRole = $event.name
				value = payload
			}

			if (target !== "globalRoleId") {
				value = $event ? $event.target.value : payload
			}
			this.$store.commit("user/updateUserData", {target, value})
		},
	},

	computed: {
		firstName: {
			get() {
				return this.$store.getters["user/getUserFirstName"] || ""
			},
			set(value) {
				let target = "firstName"
				this.$store.commit("user/updateUserData", {target, value})
			},
		},
		lastName: {
			get() {
				return this.$store.getters["user/getUserLastName"] || ""
			},
			set(value) {
				let target = "lastName"
				this.$store.commit("user/updateUserData", {target, value})
			},
		},
		email: {
			get() {
				return this.$store.getters["user/getUserEmail"] || ""
			},
			set(value) {
				let target = "email"
				this.$store.commit("user/updateUserData", {target, value})
			},
		},
		username: {
			get() {
				return this.$store.getters["user/getUserUsername"] || ""
			},
			set(value) {
				let target = "username"
				this.$store.commit("user/updateUserData", {target, value})
			},
		},
		password: {
			get() {
				return this.$store.getters["user/getUserPassword"] || ""
			},
			set(value) {
				let target = "password"
				this.$store.commit("user/updateUserData", {target, value})
			},
		},
		globalRoleId: {
			get() {
				return this.$store.getters["user/getUserGlobalRoleId"] || ""
			},
			set(value) {
				let target = "globalRoleId"
				this.$store.commit("user/updateUserData", {target, value})
			},
		},
		usersRole() {
			let theRole = this.rolesArray.filter((role) => role.id === this.getUserGlobalRoleId)
			return theRole[0]?.name || ""
		},
		formValid() {
			const {length} = this
			return +length > 0
		},
		isEditUser() {
			return this.$route.name === "Edit User" || this.$route.name === "Edit Users"
		},
		computedErrorMessage() {
			if (this.errorMessage.length > 0) {
				return this.errorMessage
			}
			return ""
		},
		...mapGetters("user", ["getUserAvatarUrl", "getUserGlobalRoleId", "getUserUsername"]),
		...mapGetters("auth", ["getAuthRoleName", "getAuthData"]),
		authIsAdmin() {
			return this.getAuthRoleName == "operator"
		},
	},
}
</script>
<style scoped>
@import "../../../node_modules/vue-next-select/dist/index.min.css";
</style>
